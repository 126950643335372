<template>
  <div class="page-map-view" :style="{'backgroundImage':'url('+bgImg+')'}">
    <div class="page-map">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="14">
          <div :class="className" id="map" :style="{height:height,width:width}"/>
        </el-col>
        <el-col :xs="24" :sm="10">
          <div class="city-view" v-for="(item,index) in cityData" :key="index">
            <div><h3>{{ item.cityName }}</h3></div>
            <div class="city-list">
              <button v-for="(lib,lindex) in item.cityLibraryDetailVoList" :key="lindex">{{ lib.name }}</button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'

require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import 'echarts/map/js/china' // 引入中国地图数据
import {provinceLibraryCount, cityLibrary} from "@/api/common";

export default {
  name: "LibraryMap",
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '500px'
    }
  },
  data() {
    return {
      chart: null,
      bgImg: require('@/assets/images/bg.jpg'),
      provinceData: [],
      cityData: [],
    }
  },
  mounted() {
    this.getProvinceData()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    async getProvinceData() {
      let result = await provinceLibraryCount()
      if (result.code === 1) {
        let tempData = result.data
        let tempArr = []
        for (let i = 0; i < tempData.length; i++) {
          tempData[i].name = tempData[i].name.replace("省", "")
          tempData[i].name = tempData[i].name.replace("自治区", "")
          tempData[i].name = tempData[i].name.replace("特别行政区", "")
          tempData[i].name = tempData[i].name.replace("回族", "")
          tempData[i].name = tempData[i].name.replace("壮族", "")
          tempData[i].name = tempData[i].name.replace("维吾尔", "")
          tempArr.push({
            "id": tempData[i].value,
            "name": tempData[i].name,
            "value": tempData[i].count,
          })
        }
        tempArr.push({
          "id": 0,
          "name": "南海诸岛",
          "value": 0
        })
        this.provinceData = tempArr
        this.initChart()
        await this.getCityData(tempArr[0].id)
      }
    },
    async getCityData(provinceId) {
      let result = await cityLibrary({"province": provinceId})
      if (result.code === 1) {
          this.cityData = result.data
      }
    },
    initChart() {
      let self = this
      this.chart = echarts.init(document.getElementById("map"), 'macarons')
      // 地图点击
      this.chart.on('click', function (params) {
        console.log(params.data)
        //请求城市
        self.getCityData(params.data.id)
      })
      this.chart.setOption({ // 进行相关配置
        backgroundColor: "",
        tooltip: {
          show: true,
          formatter: function (params) {
            return params.name + '<br>'
                + "图书馆：" + params.data['value'] + "家<br>"
          },
        }, // 鼠标移到图里面的浮动提示框
        dataRange: {
          show: true,
          min: 1,
          max: 10,
          text: ['高', '低'],
          realtime: true,
          calculable: true,
          color: ['orangered', 'yellow', 'lightskyblue']
        },
        geo: { // 这个是重点配置区
          map: 'china', // 表示中国地图
          label: {
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                color: 'rgba(0,0,0,0.4)'
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: [{
          type: 'scatter',
          coordinateSystem: 'geo' // 对应上方配置
        },
          {
            name: '数据统计', // 浮动框的标题
            type: 'map',
            geoIndex: 0,
            data: self.provinceData
          }
        ]
      })

    }
  }
}
</script>

<style scoped>
.page-map-view {
  background-position: center;
  padding: 20px 0;
}

.page-map {
  max-width: 1200px;
  margin: 0 auto;
}

#map {
  margin-top: 40px;
}

.city-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.city-view > div:first-child {
  padding: 5px 10px;
  margin-top: 40px;
}

.city-view > div:first-child h3 {
  padding: 0;
  margin: 0;
}

.city-list button {
  width: 200px;
  margin: 10px;
  border-radius: 20px;
  border: none;
  padding: 10px;
  background: #1563aa;
  color: white;
}
</style>
