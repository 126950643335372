<template>
  <div class="page">
    <Category v-if="nowMenu.type === 1" :menuId="nowMenu.id" :tab="tab" :key="nowMenu.id" />
    <LibraryMap v-if="nowMenu.id === 3" />
    <JoinApply v-if="nowMenu.id === 7" />
    <Certificate v-if="nowMenu.id === 8" />
  </div>
</template>

<script>
import Category from "@/components/Category";
import LibraryMap from "@/components/LibraryMap";
import JoinApply from "@/components/JoinApply";
import Certificate from "@/components/Certificate";
export default {
  name: "list",
  components: { Certificate, JoinApply, LibraryMap, Category},
  data(){
    return{
      nowMenu: {},
      tab: 0,
    }
  },
  created() {
    this.nowMenu = JSON.parse(localStorage.getItem("nowMenu"));
    document.title = this.nowMenu.name;
    console.log(this.nowMenu);

    let type = this.$route.query.type
    if(type){
      this.tab = Number(type)
    }
  },
  watch:{
    $route(){
      console.log(this.$route.query.id);
      this.nowMenu = JSON.parse(localStorage.getItem("nowMenu"));
      document.title = this.nowMenu.name;
      console.log(this.nowMenu);
    },
  }
}
</script>

<style scoped>

</style>
