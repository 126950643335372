<template>
  <div class="page-join-view">
    <div class="page-join">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>证书查询</span>
        </div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="姓名">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="证书编号">
            <el-input v-model="form.number" placeholder="请输入证书编号"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input v-model="form.pid" placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">查询</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>


    <el-dialog
        title="查询结果"
        :visible.sync="dialogVisible"
        width="40%">
      <div v-for="(item, index) in list" :key="index" style="border: 1px solid gray; margin-bottom: 10px">
        <div style="text-align: center">
          <h3>{{ item.name }}</h3>
        </div>
        <img v-if="item.fileType === 0" :src="ossUrl + item.fileUrl" style="width: 100%" />
        <div v-if="item.fileType === 1" style="padding: 10px; text-align: center">
          <a :href="ossUrl + item.fileUrl" target="_blank">查看证书</a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {certPageList} from "@/api/common";
import configs from "@/settings";

export default {
  name: "Certificate",
  data() {
    return {
      form: {
        "name": "",
        "number": "",
        "phone": "",
        "pid": "",
      },
      list: [],
      ossUrl: configs.ossUrl,
      dialogVisible: false,
    }
  },
  created() {

  },
  methods: {
    onSubmit() {
      if(this.form.name === "" && this.form.number === "" && this.form.phone === "" && this.form.pid === ""){
        this.$message({
          message: '至少输入一项查询条件',
          type: 'warning'
        });
        return
      }
      this.addApply()
    },
    async addApply(){
      let result = await certPageList(this.form)
      if(result.code === 1){
        this.list = result.data.list
        if(result.data.total === 0){
          this.$message({
            message: '查询无结果',
            type: 'warning'
          });
        }else{
          this.dialogVisible = true
        }
      }
    }
  }
}
</script>

<style scoped>
.page-join-view {
  padding: 20px;
}

.page-join {
  max-width: 450px;
  margin: 0 auto;
}

.org-info {
  font-size: 14px;
  color: #c0c4cc;
  margin-left: 36px;
  border-bottom: 1px solid gainsboro;
  padding: 5px;
}

</style>
