<template>
  <div class="page-category-view">
    <div class="page-category">
      <div v-for="(item,index) in list" :key="index">
        <label :class="activeIndex === index ? 'active' : ''" @click="changeCategory(index)">{{ item.name }}</label>
      </div>
    </div>
    <div class="page-content">
      <div v-if="nowCategory.type === 0">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="18">
            <div v-html="nowCategory.content"></div>
          </el-col>
          <el-col :xs="24" :sm="6">
            <div style="padding: 0 20px; text-align: center;">
              <h3>新闻动态</h3>
              <NewsList/>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="nowCategory.type === 1">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="18">
            <ArticleListImg v-if="nowCategory.name === '专家团队'" :categoryId="nowCategory.id" :key="nowCategory.id"/>
            <ArticleList v-else :categoryId="nowCategory.id" :key="nowCategory.id"/>
          </el-col>
          <el-col :xs="24" :sm="6">
            <div style="padding: 0 20px; text-align: center;">
              <h3>新闻动态</h3>
              <NewsList/>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {categoryList} from "@/api/common";
import ArticleList from "@/components/ArticleList";
import ArticleListImg from "@/components/ArticleListImg";
import NewsList from "@/components/NewsList";

export default {
  name: "Category",
  components: {NewsList, ArticleListImg, ArticleList},
  props: {
    menuId: {
      type: Number,
      default: 0,
      required: true
    },
    tab: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      list: [],
      activeIndex: 0,
      nowCategory: ""
    }
  },
  created() {
    this.activeIndex = this.tab
    this.getCategory()
  },
  methods: {
    async getCategory() {
      let result = await categoryList({"websiteColumnId": this.menuId})
      if (result.code === 1) {
        this.list = result.data;
        if (this.list.length > 0) {
          this.nowCategory = this.list[this.tab];
        }
      }
    },
    changeCategory(index) {
      this.activeIndex = index
      this.nowCategory = this.list[index];
    }
  }
}
</script>

<style scoped>
.page-category {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.page-category > div {
  padding: 20px;
}

.page-category > div label {
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
}

.page-category > div label:hover {
  color: #1563AA;
}

.page-category .active {
  color: #1563AA;
}

.page-content {
  max-width: 1200px;
  margin: 0 auto;
}
</style>