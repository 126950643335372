<template>
  <div class="page-join-view">
    <div class="page-join">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>提交加盟申请</span>
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-width="125px">
          <el-form-item label="机构名称" prop="orgName">
            <el-input v-model="form.orgName" placeholder="请输入机构名称"></el-input>
          </el-form-item>
          <el-form-item label="机构地址" prop="orgAddress">
            <el-input v-model="form.orgAddress" placeholder="请输入机构地址"></el-input>
          </el-form-item>

          <el-form-item label="法定代表人" prop="frName">
            <el-col :span="10">
              <el-input v-model="form.frName" placeholder="请输入法定代表人"></el-input>
            </el-col>
            <el-col :span="14">
              <el-form-item label="联系电话" prop="frPhone">
                <el-input v-model="form.frPhone" placeholder="请输入联系电话"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="机构目前的业务" prop="work">
            <el-radio-group v-model="form.work">
              <el-radio label="0">培训机构</el-radio>
              <el-radio label="1">书店</el-radio>
              <el-radio label="2">家庭教育</el-radio>
              <el-radio label="3">其他</el-radio>
            </el-radio-group>
          </el-form-item>

          <p class="org-info">分支机构信息</p>
          <el-row>
            <el-col :span="8">
              <el-form-item label="分支数量">
                <el-input v-model="form.branchOrgCount" placeholder="请输入分支数量"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="服务总人数">
                <el-input v-model="form.branchOrgServiceCount" placeholder="请输入服务总人数"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="员工总人数">
                <el-input v-model="form.branchOrgStaffCount" placeholder="请输入员工总人数"></el-input>
              </el-form-item>
            </el-col>
          </el-row>


          <el-form-item label="机构简介" prop="remark">
            <el-input type="textarea" v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">提交申请</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import {joinApply} from "@/api/common";

export default {
  name: "JoinApply",
  data() {
    return {
      form: {
        "branchOrgCount": "",
        "branchOrgServiceCount": "",
        "branchOrgStaffCount": "",
        "frName": "",
        "frPhone": "",
        "orgAddress": "",
        "orgName": "",
        "remark": "",
        "work": ""
      },
      rules: {
        orgName: [
          { required: true, message: '请输入机构名称', trigger: 'blur' }
        ],
        orgAddress: [
          { required: true, message: '请输入机构地址', trigger: 'blur' }
        ],
        frName: [
          { required: true, message: '请输入法定代表人', trigger: 'blur' }
        ],
        frPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        work: [
          { required: true, message: '请选择机构目前的业务', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入机构介绍', trigger: 'blur' }
        ],
      }
    }
  },
  created() {

  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid){
          this.$message({
            message: '请输入必填项',
            type: 'warning'
          });
          return false;
        }
        this.addApply()
      });
    },
    async addApply(){
      let result = await joinApply(this.form)
      if(result.code === 1){
        this.$message({
          message: '申请提交成功！',
          type: 'success'
        });
        this.form = {
          "branchOrgCount": "",
          "branchOrgServiceCount": "",
          "branchOrgStaffCount": "",
          "frName": "",
          "frPhone": "",
          "orgAddress": "",
          "orgName": "",
          "remark": "",
          "work": ""
        }
      }
    }
  }
}
</script>

<style scoped>
.page-join-view {
  padding: 20px;
}

.page-join {
  max-width: 850px;
  margin: 0 auto;
}

.org-info {
  font-size: 14px;
  color: #c0c4cc;
  margin-left: 36px;
  border-bottom: 1px solid gainsboro;
  padding: 5px;
}

</style>