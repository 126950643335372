<template>
  <div class="page-article-list">
    <div class="article-list">
      <div v-for="(item,index) in list" :key="index" @click="toArticle(item)">
        <div>
          <div>
            <img :src="ossUrl + item.photo">
          </div>
          <div>
            <h3>{{ item.title }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {articlePageList} from "@/api/common";
import configs from "@/settings";

export default {
  name: "NewsList",
  data() {
    return {
      list: [],
      ossUrl: configs.ossUrl,
    }
  },
  created() {
    this.getArticleList()
  },
  methods: {
    async getArticleList() {
      let result = await articlePageList({"websiteColumnId": 4, "pageSize": 2})
      if (result.code === 1) {
        this.list = result.data.list;
      }
    },
    toArticle(item) {
      if(item.isLink === 1) {
        window.open(item.link)
        return
      }
      this.$router.push({path: '/article', query: {id: item.id}});
    }
  }
}
</script>

<style scoped>
.article-list {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
  padding-top: 20px;
}

.article-list > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  background: aliceblue;
  justify-content: flex-end;
}

.article-list > div > div {
  padding: 20px;
}

.article-list > div:hover {
  background: #d5e8f9;
}

.article-list > div img {
  width: 200px;
  cursor: pointer;
}

.article-list h3 {
  cursor: pointer;
}
</style>